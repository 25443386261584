// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-tutorials-demo-tsx": () => import("./../src/templates/Tutorials-demo.tsx" /* webpackChunkName: "component---src-templates-tutorials-demo-tsx" */),
  "component---src-templates-tutorials-tsx": () => import("./../src/templates/Tutorials.tsx" /* webpackChunkName: "component---src-templates-tutorials-tsx" */),
  "component---src-templates-page-tsx": () => import("./../src/templates/Page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */),
  "component---src-templates-profile-tsx": () => import("./../src/templates/Profile.tsx" /* webpackChunkName: "component---src-templates-profile-tsx" */),
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-change-password-tsx": () => import("./../src/pages/change-password.tsx" /* webpackChunkName: "component---src-pages-change-password-tsx" */),
  "component---src-pages-contact-us-tsx": () => import("./../src/pages/contact-us.tsx" /* webpackChunkName: "component---src-pages-contact-us-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */)
}

