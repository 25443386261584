import { Ref } from 'react';

export const mergeRefs = <T>(...refs: Array<Ref<T> | undefined>) => (ref: T) => {
  refs.forEach(resolvableRef => {
    if (typeof resolvableRef === 'function') {
      resolvableRef(ref);
    } else if (typeof resolvableRef === 'object' && resolvableRef) {
      (resolvableRef as any).current = ref;
    }
  });
};

export const parseQueryString = (search: string) => {
  const objURL: { [index: string]: any } = {};
  search.replace(new RegExp('([^?=&]+)(=([^&]*))?', 'g'), (_1, $1: string, _2, $3: string) => {
    objURL[$1] = $3;
    return '';
  });
  return objURL;
};

export const slugify = (str: string) =>
  str
    .toLowerCase()
    .split(' ')
    .join('-');
