module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-twitter/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-responsive-image","options":{"maxWidth":840}},"gatsby-remark-responsive-iframe","gatsby-remark-katex",{"resolve":"/opt/build/repo/plugins/gatsby-remark-embedder"},{"resolve":"gatsby-remark-prismjs","options":{"languageExtensions":[{"language":"multi-r","extend":"r","definition":{}},{"language":"multi-python","extend":"python","definition":{}},{"language":"multi-stata","extend":"","definition":{}}]}},"gatsby-remark-copy-linked-files","gatsby-remark-smartypants",{"resolve":"/opt/build/repo/plugins/gatsby-remark-quiz"}],"remarkPlugins":[null]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-ZZ7W4Q9DNC"]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
