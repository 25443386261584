import { createStore, combineReducers } from 'redux';
import quizReducer from './reducers/quiz';
import playgroundReducer from './reducers/playground';
import dataReducer from './reducers/data';
import uiReducer from './reducers/ui';

const combinedReducers = combineReducers({
  data: dataReducer,
  playground: playgroundReducer,
  quiz: quizReducer,
  ui: uiReducer,
});

const reducer = combinedReducers;

export default () => createStore(reducer);
