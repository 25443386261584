import React, { createContext, useState, useEffect, useContext, useCallback, ReactNode } from 'react';
import { useAuth0 } from 'src/utils/auth';
import api from 'src/api';

interface StoredCourse {
  completed: boolean;
}
export interface CompletedCourses {
  [courseSlug: string]: StoredCourse;
}

export const RetrievedCompletedCourseContext = createContext<CompletedCourses | null>(null);

export const RetrievedCompletedCourseProvider = ({ children }: { children: ReactNode }) => {
  const { user } = useAuth0();
  const [retrievedCompletedCourse, setRetrievedCompletedCourse] = useState(null);
  useEffect(() => {
    if (!user) return;
    (async () => {
      const {
        data: { completedCourse: fetchedCompletedCourse },
      } = await api.getCompletedCourses();
      setRetrievedCompletedCourse(fetchedCompletedCourse);
    })();
  }, [user]);
  return (
    <RetrievedCompletedCourseContext.Provider value={retrievedCompletedCourse}>
      {children}
    </RetrievedCompletedCourseContext.Provider>
  );
};

export const useCompletedCourses = () => {
  const { user } = useAuth0();
  const [completedCourses, setCompletedCourse] = useState<CompletedCourses>({});
  const retrievedCompletedCourse = useContext(RetrievedCompletedCourseContext);

  const completeCourse = useCallback(
    (slug: string) => {
      if (!user) return;
      const update = { [slug]: { completed: true } };
      api.saveCompletedCourses(update);
      setCompletedCourse(c => ({ ...c, ...update }));
    },
    [user],
  );

  useEffect(() => {
    if (!retrievedCompletedCourse) return;
    setCompletedCourse(prev => ({ ...prev, ...retrievedCompletedCourse }));
  }, [retrievedCompletedCourse]);

  return [completedCourses, completeCourse] as const;
};
