import React from 'react';
import createStore from 'src/createStore';
import { Provider } from 'react-redux';
import { StripeProvider } from 'src/utils/Stripe';
import { Auth0Provider } from 'src/utils/auth';
import { RetrievedCompletedCourseProvider } from 'src/utils/completeCourse';
// @ts-ignore
import isServerSide from 'src/utils/isServerSide.js';
import isProduction from 'src/utils/isProduction.js';

// @ts-ignore
export default ({ children }) => {
  const store = createStore();
  return (
    <Auth0Provider
      domain={process.env.AUTH0_DOMAIN}
      client_id={process.env.AUTH0_CLIENTID}
      redirect_uri={isServerSide() ? '' : window.location.origin}
    >
      <RetrievedCompletedCourseProvider>
        <StripeProvider
          apiKey={isProduction() ? process.env.STRIPE_PUBLISHABLE_KEY : process.env.STRIPE_TEST_PUBLISHABLE_KEY}
        >
          <Provider store={store}>{children}</Provider>
        </StripeProvider>
      </RetrievedCompletedCourseProvider>
    </Auth0Provider>
  );
};
