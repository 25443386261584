import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import api from 'src/api';
import { parseQueryString } from './utils';
import { useStaticQuery, graphql } from 'gatsby';
import { GetStripePlansQuery } from 'types/graphql-types';

interface StripeProviderProps {
  apiKey: string;
  onSuccess?: (sessionId: string) => void;
  children: React.ReactNode;
}

type BuyMethod = (userId: string, coupon?: string) => Promise<void>;
interface IPaymentContext {
  loading: boolean;
  buy: BuyMethod;
}
const PaymentContext = React.createContext({} as IPaymentContext);

export const StripeProvider = ({ apiKey, onSuccess, children }: StripeProviderProps) => {
  const [stripe, setStripe] = React.useState<any>(null);

  const [loading, setLoading] = React.useState(false);

  const {
    allStripePlan: { nodes: plans },
    allStripeProduct: { nodes: products },
    site,
  } = useStaticQuery<GetStripePlansQuery>(graphql`
    query GetStripePlans {
      site {
        siteMetadata {
          stripeProductName
        }
      }
      allStripePlan {
        nodes {
          id
          product
        }
      }
      allStripeProduct {
        nodes {
          id
          name
        }
      }
    }
  `);
  const stripeProductName = site?.siteMetadata?.stripeProductName;
  const productId = products.find(product => product.name === stripeProductName)?.id;
  const currentPlan = plans.find(plan => plan.product === productId);

  React.useEffect(() => {
    const searchParams = parseQueryString(window.location.search);
    if (searchParams['stripe-session-id']) {
      const sessionId = searchParams['stripe-session-id'];
      if (onSuccess) {
        onSuccess(sessionId);
      }
    }
  }, []);

  const buy = React.useCallback<BuyMethod>(
    (userId: string, coupon?: string) => {
      if (loading) return Promise.resolve();
      setLoading(true);
      return api
        .buy(
          userId,
          currentPlan?.id || '',
          location.origin + '?stripe-session-id={CHECKOUT_SESSION_ID}',
          location.origin,
          coupon,
        )
        .then(res => {
          stripe
            ?.redirectToCheckout({
              sessionId: res.data.session_id,
            })
            .then((result: any) => {
              setLoading(false);
              if (result.error.message) {
                console.log(result.error.message);
              }
            });
        })
        .catch(err => {
          console.error(err);
          setLoading(false);
        });
    },
    [stripe],
  );
  React.useEffect(() => {
    if (typeof window === 'undefined') {
      // We can also make this work with server side rendering (SSR) by
      // returning early when not in a browser environment.
      return;
    }

    // You can inject a script tag manually like this, or you can just
    // use the 'async' attribute on the Stripe.js v3 script tag.
    const stripeJs = document.createElement('script');
    stripeJs.src = 'https://js.stripe.com/v3/';
    stripeJs.async = true;
    stripeJs.onload = () => {
      setStripe(
        // @ts-ignore
        window.Stripe(apiKey),
      );
    };
    document.body.appendChild(stripeJs);
  }, []);
  return (
    <PaymentContext.Provider
      value={{
        buy,
        loading,
      }}
    >
      <Elements stripe={stripe}>{children}</Elements>
    </PaymentContext.Provider>
  );
};

export const usePayment = () => {
  return React.useContext(PaymentContext);
};
