import React from 'react';
import createAuth0Client from '@auth0/auth0-spa-js';
import { navigate } from 'gatsby';
import Auth0Client from '@auth0/auth0-spa-js/dist/typings/Auth0Client';
import isServerSide from './isServerSide';
import * as Cookie from 'es-cookie';
import isProduction from 'src/utils/isProduction';

const namespace = 'https://artofmetrics.com/';
export const customClaims = {
  premium: `${namespace}premium`,
  latestActivity: `${namespace}latestActivity`,
  subscriptionWillCancelAt: `${namespace}subscriptionWillCancelAt`,
  subscriptionPeriodEndAt: `${namespace}subscriptionPeriodEndAt`,
};
interface Auth0ContextValue {
  isAuthenticated: boolean;
  user: any;
  loading: boolean;
  popupOpen: boolean;
  loginWithPopup: (params?: PopupLoginOptions) => Promise<void>;
  handleRedirectCallback: () => void;
  getIdTokenClaims: Auth0Client['getIdTokenClaims'];
  loginWithRedirect: Auth0Client['loginWithRedirect'];
  getNewToken: Auth0Client['loginWithRedirect'];
  getTokenSilently: Auth0Client['getTokenSilently'];
  getTokenWithPopup: Auth0Client['getTokenWithPopup'];
  logout: Auth0Client['logout'];
  getAuth0User: (ignoreCache?: boolean) => Promise<any | undefined>;
}
export const Auth0Context = React.createContext<Auth0ContextValue>(({} as unknown) as Auth0ContextValue);
export const useAuth0 = () => React.useContext(Auth0Context);

type RedirectCallback = (appState: RedirectLoginResult['appState']) => void;
interface Auth0ProviderProps extends Auth0ClientOptions {
  children: React.ReactNode;
  onRedirectCallback?: RedirectCallback;
}

const DEFAULT_REDIRECT_CALLBACK: RedirectCallback = appState => {
  navigate(appState?.targetUrl || window.location.pathname, { replace: true });
};

export const Auth0Provider = ({
  children,
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  ...initOptions
}: Auth0ProviderProps) => {
  const [isAuthenticated, setIsAuthenticated] = React.useState<boolean>(false);
  const [user, setUser] = React.useState<any>();
  const [auth0Client, setAuth0] = React.useState<Auth0Client>();
  const [loading, setLoading] = React.useState(true);
  const [popupOpen, setPopupOpen] = React.useState(false);

  const getAuth0User = React.useCallback(
    async (ignoreCache = false) => {
      if (!auth0Client) return;
      if (ignoreCache) await auth0Client.getTokenSilently({ ignoreCache: true, audience: '', scope: '' });
      let isCurrentlyAuthenticated = await auth0Client.isAuthenticated();
      if (!isCurrentlyAuthenticated) {
        try {
          await auth0Client.getTokenSilently();
          isCurrentlyAuthenticated = await auth0Client.isAuthenticated();
        } catch (e) {
          // Pass
        }
      }

      if (isCurrentlyAuthenticated) {
        const currentUser = await auth0Client.getUser();
        setUser(currentUser);
        setIsAuthenticated(true);
        return currentUser;
      } else {
        setIsAuthenticated(false);
        removeAuthCookie();
        return;
      }
    },
    [auth0Client],
  );

  React.useEffect(() => {
    getAuth0User();
  }, [auth0Client]);

  const { setAuthCookie, removeAuthCookie } = React.useMemo(() => {
    const key = 'bearer';
    const path = '/';
    const domain = '.cauzl.com';
    return {
      setAuthCookie: (token: string) => {
        Cookie.set(key, token, {
          path,
          domain,
          sameSite: 'lax',
          secure: true,
          expires: 10,
        });
      },
      removeAuthCookie: () => {
        Cookie.remove(key, {
          path,
          domain,
          sameSite: 'lax',
          secure: true,
          expires: 10,
        });
      },
    };
  }, []);

  React.useEffect(() => {
    const init = async () => {
      const auth0FromHook = await createAuth0Client(initOptions);
      setAuth0(auth0FromHook);

      if (window.location.search.includes('code=')) {
        const { appState } = await auth0FromHook.handleRedirectCallback();
        onRedirectCallback(appState);
      }

      setLoading(false);
    };
    init();
  }, []);

  React.useEffect(() => {
    if (!isProduction()) return;
    (async () => {
      const token = await auth0Client?.getIdTokenClaims();
      const isPremium: boolean = !!token && (token as any)[customClaims.premium];
      const urlIsPremium = window.location.hostname.startsWith('learn');
      if (token) {
        setAuthCookie(token.__raw);
        if (isPremium && !urlIsPremium) {
          window.location.reload();
        } else if (!isPremium && urlIsPremium) {
          window.location.reload();
        }
      }
    })();
  }, [user]);

  const transformedUser = React.useMemo(() => user && { ...user, connectionType: user?.sub?.split('|')[0] || '' }, [
    user,
  ]);

  const loginWithPopup = async (params = {}) => {
    setPopupOpen(true);
    try {
      await auth0Client?.loginWithPopup(params);
    } catch (error) {
      throw error;
    } finally {
      setPopupOpen(false);
    }
    getAuth0User();
  };

  const handleRedirectCallback = async () => {
    setLoading(true);
    await auth0Client?.handleRedirectCallback();
    await getAuth0User();
    setLoading(false);
  };

  return (
    <Auth0Context.Provider
      value={{
        isAuthenticated,
        user: transformedUser,
        loading,
        popupOpen,
        loginWithPopup,
        handleRedirectCallback,
        // @ts-ignore
        getIdTokenClaims: (...p) => auth0Client?.getIdTokenClaims(...p),
        // @ts-ignore
        loginWithRedirect: (...p) => auth0Client?.loginWithRedirect(...p),
        // @ts-ignore
        getTokenSilently: (...p) => auth0Client?.getTokenSilently(...p),
        // @ts-ignore
        getNewToken: options => auth0Client?.getTokenSilently({ ...options, ignoreCache: true }),
        // @ts-ignore
        getTokenWithPopup: (...p) => auth0Client?.getTokenWithPopup(...p),
        // @ts-ignore
        logout: (...p) => {
          removeAuthCookie();
          return auth0Client?.logout({ returnTo: window.location.origin, ...p });
        },
        getAuth0User,
      }}
    >
      {children}
    </Auth0Context.Provider>
  );
};

interface AuthButtonProps {
  renderLoading: () => JSX.Element;
  renderLoggedIn: (user: any, logout: () => void) => JSX.Element;
  renderLoggedOut: (login: () => void) => JSX.Element;
}
export const AuthButton: React.FC<AuthButtonProps> = ({ renderLoading, renderLoggedIn, renderLoggedOut }) => {
  const { isAuthenticated, user, loading, loginWithPopup, logout } = useAuth0();
  if (loading || isServerSide()) {
    return renderLoading();
  } else
    return isAuthenticated
      ? renderLoggedIn(user, () => logout({ returnTo: location.pathname }))
      : renderLoggedOut(loginWithPopup);
};
