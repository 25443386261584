import lambdaInstance from './axios';
import { BuyResponse } from 'src/lambda/functions/buy';
import { ActivityResponse } from 'src/lambda/functions/submit-activity';
import { Activity, CompletedCourses } from 'src/utils/hooks';
import { UpdateCompletedCoursesResponse } from 'src/lambda/functions/update-completed-courses';
import { SubscribeResponse } from 'src/lambda/functions/coming-soon-subscribe';
import { ChangePasswordResponse } from 'src/lambda/functions/change-password';
import { ValidateCouponResponse } from 'src/lambda/functions/validate-coupon';

export default {
  buy: (userId: string, planId: string, successUrl: string, cancelUrl: string, coupon?: string) => {
    return lambdaInstance.post<BuyResponse>('/buy', {
      auth0_id: userId,
      plan_id: planId,
      success_url: successUrl,
      cancel_url: cancelUrl,
      coupon,
    });
  },
  saveLatestActivity: (userId: string, activity: Activity) => {
    return lambdaInstance.post<ActivityResponse>('/submit-activity', {
      auth0_id: userId,
      activity,
    });
  },
  getCompletedCourses: () => {
    return lambdaInstance.get('/get-completed-courses');
  },
  saveCompletedCourses: (completedCourses: CompletedCourses) => {
    return lambdaInstance.post<UpdateCompletedCoursesResponse>('/update-completed-courses', {
      completedCourses,
    });
  },
  subscribe: (audienceId: string, emailAddress: string) => {
    return lambdaInstance.post<SubscribeResponse>('/coming-soon-subscribe', {
      audience_id: audienceId,
      email_address: emailAddress,
    });
  },
  changePassword: (password: string) => {
    return lambdaInstance.patch<ChangePasswordResponse>('/change-password', {
      password,
    });
  },
  cancelPremium: () => {
    return lambdaInstance.patch('/toggle-premium', { cancel: true });
  },
  resumePremium: () => {
    return lambdaInstance.patch('/toggle-premium', { cancel: false });
  },
  validateCoupon: ({ coupon }: { coupon: string }) => {
    return lambdaInstance.post<ValidateCouponResponse>('/validate-coupon', { coupon });
  },
};
